<template>
  <div id="bsMerchantCCModal" aria-labelledby="bsMerchantCCModalLabel" class="modal fade" data-backdrop="static"
    data-keyboard="false" data-toggle="modal" role="dialog" tabindex="-1" @click="clickOverlay()">
    <div class="modal-dialog" role="document" @click.stop>
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" type="button" @click="close()">
            <span aria-hidden="true">&times;</span></button>
          <h4 id="bsMerchantCCModalLabel" class="modal-title">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <div v-if="form.Key === 'Name'">
                <ValidationProvider v-slot="{ errors }" class="form-group" :name="form.Key" rules="required" tag="div">
                  <label>{{ form.Key }}</label>
                  <input type="text" v-model="form.Value" class="form-control">
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div v-if="form.Key === 'UsedID'">
                <ValidationProvider v-slot="{ errors }" class="form-group" :name="form.Key"
                  :rules="{ required: true, regex: /^-?[0-9]+/ }" tag="div">
                  <label>{{ form.Key }}</label>
                  <input type="text" v-model="form.Value" class="form-control">
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div v-if="form.Key === 'Active'">
                <ValidationProvider v-slot="{ errors }" class="form-group" :name="form.Key" rules="required" tag="div">
                  <label>{{ form.Key }}</label>
                  <select v-model="form.Value" class="form-control">
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                  </select>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div v-if="form.Key === 'BasicOnly'">
                <ValidationProvider v-slot="{ errors }" class="form-group" :name="form.Key" rules="required" tag="div">
                  <label>{{ form.Key }}</label>
                  <select v-model="form.Value" class="form-control">
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                  </select>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div v-if="form.Key === 'URLString'">
                <ValidationProvider v-slot="{ errors }" class="form-group" name="url string" rules="required" tag="div">
                  <label>URL String</label>
                  <editor v-model="form.Value" :model.sync="form.Value"></editor>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="text-right">
                <button :disabled="isLoading" class="btn btn-primary btn-flat" type="submit">
                  <span v-if="!isLoading">Save</span>
                  <span v-if="isLoading"><i class="fa fa-circle-o-notch fa-spin"></i></span>
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { saveNotification } from "@/lib/utils";
import { mapGetters } from "vuex";

export default {
  name: "MerchantCCModal",
  data() {
    return {
      isLoading: false,
      title: "",
      record: null,
      form: {
        ID: "",
        Key: "",
        Value: "",
      },
      confirmHook: () => {
      },
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
    ]),
  },
  watch: {
    "form.Value"(val) {
      if (this.form.Key === "UsedID") {
        this.form.Value = val.toString().replace(/[^-?0-9]/, "");
      }
    },
  },
  methods: {
    confirm() {
      this.confirmHook();
      this.close();
    },
    onSubmit: function () {
      this.isLoading = true;
      const url = "https://app.cointopay.com/v2REAPI?Call=MerchantCCUpdate";
      this.$http.post(url, this.qs.stringify({
        ID: this.form.ID,
        Key: this.form.Key,
        Value: this.form.Value,
        MerchantID: this.currentUser.ID,
        APIKey: this.currentUser.APIKey,
        output: "json",
      }), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }).then(response => {
        this.isLoading = false;
        const message = response.data.message;
        if (response.data.status_code === 200) {
          this.$toast.fire("", message, "success");
          saveNotification(message);
          // Close modal
          this.confirm();
        } else {
          this.$toast.fire("", message, "error");
        }
      }).catch(error => {
        this.isLoading = false;
        console.log(error);
      });
    },
    clickOverlay: function () {
      // Hide modal
      this.close();
    },
    close() {
      $("#bsMerchantCCModal").modal("hide");
    },
  },
  mounted() {
    this.$bus.$on("merchant-cc-modal", (args) => {
      this.title = args.title;
      this.record = args.record || null;
      this.confirmHook = args.confirm;
      $("#bsMerchantCCModal").modal("show");
      if (this.record) {
        this.form = {
          ID: this.record.PrimaryID,
          Key: args.key,
          Value: this.record[args.key],
        };
      }
      if (args.key === "URLString") {
        $(".note-editor").eq(1).summernote("destroy");
      } else {
        $(".note-editor").summernote("destroy");
      }
    });
  },
};
</script>
