<template>
  <div>
    <div v-if="!isLoading" class="box box-default">
      <div class="box-header">
        <div class="flex gap-8 flex-col-sm align-items-center justify-between">
          <h3 class="box-title mb-0">Merchant CC</h3>
          <div class="flex flex-col-sm gap-8 align-items-center">
            <select class="form-control" v-model="type">
              <option value="">All</option>
              <option value="1">Enabled</option>
              <option value="0">Disabled</option>
            </select>
            <input type="text" v-model="name" class="form-control" placeholder="Search by Name" />
            <v-select v-model="usedId" :options="filteredOptions" style="min-width: 320px" :filter="filterVSelect"
              label="Name"></v-select>
            <button class="btn btn-primary" @click="insertMerchantCC()">Add New Merchant CC</button>
          </div>
        </div>
      </div>
      <div class="box-body no-padding">
        <div v-if="filteredRecords && filteredRecords.length" class="footer">
          <pagination :meta="meta" @pageChange="updatePage"></pagination>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-condensed">
            <thead>
              <tr>
                <th>ID</th>
                <th class="pointer" @click="sort('Name')">
                  Name
                  <span v-if="sortKey === 'Name'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('UsedID')">
                  UsedID
                  <span v-if="sortKey === 'UsedID'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th>Active</th>
                <th>URL String</th>
                <th>Merchant CC Type</th>
                <th>BasicOnly</th>
                <th>Active Card Users</th>
                <th>Bank Users</th>
                <th>CreatedOn</th>
                <th class="pointer" @click="sort('ModifiedOn')">
                  ModifiedOn
                  <span v-if="sortKey === 'ModifiedOn'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="paginatedRecords.length > 0">
                <tr v-for="(record, index) in paginatedRecords" :key="index">
                  <td>{{ record.PrimaryID }}</td>
                  <td>
                    <span class="pointer" @click="launchEditMerchantCCModal(record, 'Name')">{{ record.Name }}</span>
                  </td>
                  <td>
                    <span class="pointer" @click="launchEditMerchantCCModal(record, 'UsedID')">{{ record.UsedID }}</span>
                  </td>
                  <td>
                    <span @click="toggleMerchantCCBoolFields(record, 'Active')"
                      v-html="formatStatus(record.Active)"></span>
                  </td>
                  <td>
                    <div style="min-height:60px;max-width: 400px;" class="textarea pointer" v-html="record.URLString"
                      @click="launchEditMerchantCCModal(record, 'URLString')"></div>
                  </td>
                  <td>
                    <select class="form-control" v-model="record.Merchant_CC_Type" @change="updateMerchantCCType(record)">
                      <option v-for="type in merchantCCTypes" :value="type.ID" :key="type.ID">{{ type.TypeName }}</option>
                    </select>
                  </td>
                  <td>
                    <span class="pointer" @click="toggleMerchantCCBoolFields(record, 'BasicOnly')"
                      v-html="formatStatus(record.BasicOnly)"></span>
                  </td>
                  <td>
                    <a :href="`/admin/config-merchants-cc/${record.UsedID}`" target="_blank"
                      class="label label-primary">{{ record.TotalCCUsers }}</a>
                  </td>
                  <td>
                    <a :href="`/admin/config-merchants-bank/${record.UsedID}`" target="_blank"
                      class="label label-primary">{{ record.TotalBankUsers }}</a>
                  </td>
                  <td>{{ record.CreatedOn }}</td>
                  <td>{{ record.ModifiedOn }}</td>
                  <td class="no-wrap pr-16">
                    <button class="btn btn-danger btn-flat btn-sm" @click="deleteMerchantCC(record)">
                      Delete
                    </button>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="12">No record found!</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div v-if="filteredRecords && filteredRecords.length" class="footer">
          <pagination :meta="meta" @pageChange="updatePage"></pagination>
        </div>
      </div>
    </div>
    <merchant-c-c-modal></merchant-c-c-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { START_LOADING, STOP_LOADING } from "@/store/keys";
import { formatStatus, logout, saveNotification } from "@/lib/utils";
import MerchantCCModal from "@/components/modals/MerchantCCModal";
import Pagination from "@/components/Pagination.vue";

export default {
  name: "MerchantCC",
  components: { Pagination, MerchantCCModal },
  data() {
    return {
      records: [],
      merchantCCTypes: [],
      sortKey: "UsedID",
      order: "asc",
      currentPage: 1,
      type: "",
      usedId: null,
      name: "",
      limit: 25,
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "jwtToken",
      "currentUser",
    ]),
    filteredRecords() {
      let records = this.records.filter(r => {
        if (this.type !== "" && this.usedId) {
          this.type = "";
          return (r.UsedID.toString() === this.usedId.UsedID.toString() && r.Active.toString() === this.type);
        } else if (this.usedId) {
          return r.UsedID.toString() === this.usedId.UsedID.toString();
        } else if (this.type !== "") {
          return r.Active.toString() === this.type;
        }
        return true;
      });
      if (this.name !== "") {
        records = records.filter(r => {
          return this._.includes(r.Name.toLowerCase(), this.name.trim().toLowerCase());
        });
      }
      return records;
    },
    filteredOptions() {
      return this.records.filter(r => {
        return this._.includes(r.Name.toLowerCase(), this.name.trim().toLowerCase());
      });
    },
    paginatedRecords() {
      if (this.filteredRecords.length > 0) {
        return this.filteredRecords.slice(
          this.offset * this.limit,
          this.currentPage * this.limit,
        );
      }
      return this.filteredRecords;
    },
    offset() {
      return this.currentPage > 0 ? this.currentPage - 1 : 0;
    },
    meta() {
      return {
        total: this.filteredRecords.length,
        offset: this.offset * this.limit,
        limit: this.limit,
        page: this.currentPage,
      };
    },
  },
  watch: {
    type(val) {
      this.currentPage = 1;
    },
    name(val) {
      this.currentPage = 1;
    },
  },
  methods: {
    updatePage(page) {
      this.currentPage = page;
    },
    formatStatus(status) {
      return formatStatus(status === 1 ? "Yes" : "No", "60");
    },
    fetchMerchantCCTypes() {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListMerchantCCTypes`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
      }).then(response => {
        this.merchantCCTypes = response.data.data;
      }).catch(error => {
        saveNotification(error.data.message);
      });
    },
    filterVSelect(options, search) {
      return options.filter(option => {
        return this._.includes(option.UsedID.toString(), search) || this._.includes(option.Name.toLowerCase(), search.toLowerCase());
      });
    },
    getRecords(isLoader = true) {
      if (isLoader) {
        this.$store.commit(START_LOADING);
      }
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListMerchantCC`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          orderBy: this.sortKey,
          order: this.order,
        },
      }).then(response => {
        this.$store.commit(STOP_LOADING);
        this.records = response.data.data;
      }).catch(error => {
        this.$store.commit(STOP_LOADING);
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    sort(key) {
      if (key !== this.sortKey) {
        this.order = "desc";
      } else {
        this.order = this.order === "asc" ? "desc" : "asc";
      }
      this.sortKey = key;
      this.getRecords();
    },
    insertMerchantCC() {
      const url = `https://app.cointopay.com/v2REAPI?Call=MerchantCCInsert&output=json&MerchantID=${this.currentUser.ID}&APIKey=${this.currentUser.APIKey}`;
      this.$http.get(url).then(response => {
        if (response.data.status_code === 200) {
          this.getRecords(false);
          this.$toast.fire("", response.data.message, "success");
        } else {
          this.$toast.fire("", response.data.message, "error");
        }
      }).catch(error => {
        console.log(error);
      });
    },
    updateMerchantCCType(record) {
      const url = "https://app.cointopay.com/v2REAPI?Call=MerchantCCUpdate";
      this.$http.post(url, this.qs.stringify({
        ID: record.PrimaryID,
        Key: "Merchant_CC_Type",
        Value: record.Merchant_CC_Type,
        MerchantID: this.currentUser.ID,
        APIKey: this.currentUser.APIKey,
        output: "json",
      }), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }).then(response => {
        const message = response.data.message;
        if (response.data.status_code === 200) {
          this.$toast.fire("", message, "success");
          saveNotification(message);
          this.getRecords(false);
        } else {
          this.$toast.fire("", message, "error");
        }
      }).catch(error => {
        console.log(error);
      });
    },
    launchEditMerchantCCModal(record, key) {
      this.$bus.$emit("merchant-cc-modal", {
        title: "Update Merchant CC",
        record: record,
        key: key,
        confirm: () => {
          this.getRecords(false);
        },
      });
    },
    toggleMerchantCCBoolFields(record, key) {
      const url = "https://app.cointopay.com/v2REAPI?Call=MerchantCCUpdate";
      this.$http.post(url, this.qs.stringify({
        ID: record.PrimaryID,
        Key: key,
        Value: Number(record[key]) === 0 ? 1 : 0,
        MerchantID: this.currentUser.ID,
        APIKey: this.currentUser.APIKey,
        output: "json",
      }), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }).then(response => {
        const message = response.data.message;
        if (response.data.status_code === 200) {
          this.$toast.fire("", message, "success");
          saveNotification(message);
          this.getRecords(false);
        } else {
          this.$toast.fire("", message, "error");
        }
      }).catch(error => {
        console.log(error);
      });
    },
    deleteMerchantCC(record) {
      const index = this.records.indexOf(record);
      if (index !== -1) {
        this.$swal.fire({
          text: "Are you sure want to delete this record?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00a65a",
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          focusCancel: true,
        }).then(({ value }) => {
          if (value && value === true) {
            this.$swal.fire({
              title: "",
              text: "Please wait...",
              showConfirmButton: false,
              backdrop: true,
            });
            const apiBaseUrl = process.env.VUE_APP_API_URL;
            this.$http.get(`${apiBaseUrl}/v2REAPI`, {
              params: {
                Call: "DeleteMerchantCCID",
                MerchantID: this.currentUser.ID,
                APIKey: this.currentUser.APIKey,
                ID: record.PrimaryID,
                output: "json",
              },
              withCredentials: true,
            }).then(response => {
              this.$swal.close();
              if (response.data.status_code === 200) {
                if (response.data.result.toLowerCase() === "success") {
                  this.$toast.fire("", response.data.message, "success");
                  saveNotification(response.data.message);
                  this.records.splice(index, 1);
                }
              } else if (response.data.status_code === 400) {
                if (response.data.message.toLowerCase() === "no permission") {
                  logout();
                }
              }
            }).catch(error => {
              this.$swal.close();
              console.log(error);
            });
          }
        });
      }
    },
  },
  mounted() {
    this.getRecords();
    this.fetchMerchantCCTypes();
  },
};
</script>

<style scoped></style>
